<template>
  <div class="background"></div>
  <main class="relative h-full min-h-screen">
    <div class="nav text-center pt-5">
      <a
        href="https://www.robocock.io/"
        class="text-white tracking-widest decoration-2 font-mono nav-link"
        style="font-family: 'Paladins Straight', sans-serif; text-align:center"
      >
        ROBOCOCK.IO
      </a>
    </div>

    <div class="p-0">
      <router-view />
    </div>
  </main>
</template>

<script>
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  components: {},
};
</script>

<style>
</style>
