
import { onMounted, ref, defineComponent } from 'vue';
import axios from "axios";

export default defineComponent({
  components: {},
  props: {
    farmAcc: Object,
    stakedGems: Number
  },
  setup(props) {
    const magicEdenFloor = ref<Number>();
    const solPrice = ref<Number>();

    onMounted(async () => {
      const meRes = (await axios.get('https://api-me.robocock.io/gen1/getStats')).data;
      // const solPriceRes = (await axios.get('https://data.messari.io/api/v1/assets/solana/metrics')).data;

      magicEdenFloor.value = meRes.floorPrice/1000000000;
      // solPrice.value = solPriceRes.data.market_data.price_usd;
    });

    const getPercentGemStaked = (gemsStaked: number) => {
      return `${((gemsStaked / 3838) * 100).toFixed(2)}%`;
    };

    return {
      getPercentGemStaked,
      magicEdenFloor
    };
  },
});
