import moment from 'moment/moment';
import { BN } from '@project-serum/anchor';
import { toBN } from '@gemworks/gem-farm-ts';
import {
  Connection,
  Transaction,
  TransactionSignature,
  Commitment,
  RpcResponseAndContext,
  SimulatedTransactionResponse,
  Account,
  Keypair,
  TransactionConfirmationStatus
} from '@solana/web3.js';

const DEFAULT_TIMEOUT = 30000;

export function removeManyFromList(toRemove: any[], fromList: any[]) {
  toRemove.forEach((i) => {
    const index = fromList.indexOf(i);
    if (index > -1) {
      fromList.splice(index, 1);
    }
  });
}

//returns stuff in list1 but not in list2
export function getListDiff(list1: any[], list2: any[]): any[] {
  return list1.filter((i) => !list2.includes(i));
}

export function getListDiffBasedOnMints(list1: any[], list2: any[]): any[] {
  const list1Mints = list1.map((i) => i.mint.toBase58());
  const list2Mints = list2.map((i) => i.mint.toBase58());

  const diff = getListDiff(list1Mints, list2Mints);

  return list1.filter((i) => diff.includes(i.mint.toBase58()));
}

export function parseDate(unixTsSec: number | string | BN) {
  const unixBN = toBN(unixTsSec);
  if (unixBN.eq(new BN(0))) {
    return '--';
  }

  const dateObj = new Date(unixBN.mul(new BN(1000)).toNumber());
  return moment(dateObj).format('MMM Do YY, h:mm a');
}

async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const getUnixTs = () => {
  return new Date().getTime() / 1000;
};

async function simulateTransaction(
  connection: Connection,
  transaction: Transaction,
  commitment: Commitment,
): Promise<RpcResponseAndContext<SimulatedTransactionResponse>> {
  // @ts-ignore
  transaction.recentBlockhash = await connection._recentBlockhash(
    // @ts-ignore
    connection._disableBlockhashCaching,
  );

  const signData = transaction.serializeMessage();
  // @ts-ignore
  const wireTransaction = transaction._serialize(signData);
  const encodedTransaction = wireTransaction.toString('base64');
  const config: any = { encoding: 'base64', commitment };
  const args = [encodedTransaction, config];

  // @ts-ignore
  const res = await connection._rpcRequest('simulateTransaction', args);
  if (res.error) {
    throw new Error('failed to simulate transaction: ' + res.error.message);
  }
  return res.result;
}


export async function sendTransaction(
  transaction: Transaction,
  // payer: Account | WalletAdapter | Keypair,
  // additionalSigners: Account[],
  connection: Connection,
  timeout: number | null = 30000,
  confirmLevel: TransactionConfirmationStatus = 'processed',
): Promise<TransactionSignature> {

  const rawTransaction = transaction.serialize();
  const startTime = getUnixTs();

  const txid: TransactionSignature = await connection.sendRawTransaction(
    rawTransaction,
    { skipPreflight: true },
  );

  // if (this.postSendTxCallback) {
  //   try {
  //     this.postSendTxCallback({ txid });
  //   } catch (e) {
  //     console.log(`postSendTxCallback error ${e}`);
  //   }
  // }

  if (!timeout) return txid;

  console.log(
    'Started awaiting confirmation for',
    txid,
    'size:',
    rawTransaction.length,
  );

  let done = false;

  let retrySleep = 1000;
  (async () => {
    // TODO - make sure this works well on mainnet
    while (!done && getUnixTs() - startTime < timeout / 1000) {
      await sleep(retrySleep);
      // console.log(new Date().toUTCString(), ' sending tx ', txid);
      connection.sendRawTransaction(rawTransaction, {
        skipPreflight: true,
      });
      if (retrySleep <= 6000) {
        retrySleep = retrySleep * 2;
      }
    }
  })();

  try {
    await awaitTransactionSignatureConfirmation(
      txid,
      timeout,
      connection,
      confirmLevel,
    );
  } catch (err: any) {
    if (err.timeout) {
      throw new Error(`Timeout : ${txid}`);
    }
    let simulateResult: SimulatedTransactionResponse | null = null;
    try {
      simulateResult = (
        await simulateTransaction(connection, transaction, 'processed')
      ).value;
    } catch (e) {
      console.warn('Simulate transaction failed');
    }

    if (simulateResult && simulateResult.err) {
      if (simulateResult.logs) {
        for (let i = simulateResult.logs.length - 1; i >= 0; --i) {
          const line = simulateResult.logs[i];
          if (line.startsWith('Program log: ')) {
            throw new Error('Transaction failed: ' + line.slice('Program log: '.length));
          }
        }
      }
      throw new Error(JSON.stringify(simulateResult.err));
    }
    throw new Error(`Transaction failed ${txid}`);
  } finally {
    done = true;
  }

  console.log('Latency', txid, getUnixTs() - startTime);
  return txid;
}

async function awaitTransactionSignatureConfirmation(
  txid: TransactionSignature,
  timeout: number,
  connection: Connection,
  confirmLevel: TransactionConfirmationStatus,
) {
  let done = false;

  const confirmLevels: (TransactionConfirmationStatus | null | undefined)[] =
    ['finalized'];

  if (confirmLevel === 'confirmed') {
    confirmLevels.push('confirmed');
  } else if (confirmLevel === 'processed') {
    confirmLevels.push('confirmed');
    confirmLevels.push('processed');
  }
  let subscriptionId;
  let lastSlot;

  const result = await new Promise((resolve, reject) => {
    (async () => {
      setTimeout(() => {
        if (done) {
          return;
        }
        done = true;
        console.log('Timed out for txid: ', txid);
        reject({ timeout: true });
      }, timeout);
      try {
        subscriptionId = connection.onSignature(
          txid,
          (result, context) => {
            subscriptionId = undefined;
            done = true;
            if (result.err) {
              reject(result.err);
            } else {
              lastSlot = context?.slot;
              resolve(result);
            }
          },
          'processed',
        );
      } catch (e) {
        done = true;
        console.log('WS error in setup', txid, e);
      }
      let retrySleep = 200;
      while (!done) {
        // eslint-disable-next-line no-loop-func
        await sleep(retrySleep);
        (async () => {
          try {
            const response = await connection.getSignatureStatuses([
              txid,
            ]);

            const result = response && response.value[0];
            if (!done) {
              if (!result) {
                // console.log('REST null result for', txid, result);
              } else if (result.err) {
                console.log('REST error for', txid, result);
                done = true;
                reject(result.err);
              } else if (
                !(
                  result.confirmations ||
                  confirmLevels.includes(result.confirmationStatus)
                )
              ) {
                console.log('REST not confirmed', txid, result);
              } else {
                lastSlot = response?.context?.slot;
                console.log('REST confirmed', txid, result);
                done = true;
                resolve(result);
              }
            }
          } catch (e) {
            if (!done) {
              console.log('REST connection error: txid', txid, e);
            }
          }
        })();
        if (retrySleep <= 1600) {
          retrySleep = retrySleep * 2;
        }
      }
    })();
  });

  if (subscriptionId) {
    connection.removeSignatureListener(subscriptionId).catch((e) => {
      console.log('WS error in cleanup', e);
    });
  }

  done = true;
  return result;
}