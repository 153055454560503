
import { defineComponent, ref, onMounted } from 'vue';
import FixedScheduleDisplay from '@/components/gem-farm/FixedScheduleDisplay.vue';
import { parseDate } from '@/common/util';
import numeral from 'numeral';
import moment from 'moment/moment';

export default defineComponent({
  components: { FixedScheduleDisplay },
  props: {
    reward: Object,
    farmReward: Object,
    title: String,
    gemStaked: Number,
  },
  setup(props) {
    const parseRewardType = (reward: any): string => {
      //returns "variable" or "fixed"
      return Object.keys(reward.rewardType)[0];
    };

    const availableA = ref<string>();

    const getEstimatedRewards = () => {
      // console.log('props.reward: ', props.reward);
      if (props.reward?.accruedReward && props.gemStaked) {
        let availableRewardLastUpdate = props.reward?.accruedReward
          .sub(props.reward?.paidOutReward)
          .toString();
        let rewardsLastUpdate = parseInt(availableRewardLastUpdate);
        let rewardPerSecond = 500 / 86400;

        let secondsElapsedSinceUpdate = moment().diff(
          moment.unix(props.reward?.fixedRate.lastUpdatedTs)
        );

        let secondsSinceUpdate = moment
          .duration(secondsElapsedSinceUpdate)
          .asSeconds();

        let rewardAvailable =
          secondsSinceUpdate * rewardPerSecond * props.gemStaked +
          rewardsLastUpdate;

        availableA.value = (rewardAvailable / 100).toFixed(3);
      }

      setTimeout(getEstimatedRewards, 2500);
    };

    onMounted(async () => {
      availableA.value = props.reward?.accruedReward
        .sub(props.reward?.paidOutReward)
        .toString();

      getEstimatedRewards();
    });

    return {
      availableA,
      parseRewardType,
      parseDate,
      numeral,
    };
  },
});
