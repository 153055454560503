import { Connection, Keypair, PublicKey, SystemProgram } from '@solana/web3.js';
import { SignerWalletAdapter } from '@solana/wallet-adapter-base';
import { BN, Idl, web3 } from '@project-serum/anchor';
import { createFakeWallet } from '@/common/gem-bank';
import {
  GemFarmClient,
  FarmConfig,
  VariableRateConfig,
  FixedRateConfig,
  WhitelistType,
  findWhitelistProofPDA,
  GEM_FARM_PROG_ID,
  GEM_BANK_PROG_ID,
  findGemBoxPDA,
  findVaultPDA,
  findFarmerPDA,
  findFarmAuthorityPDA,
  findFarmTreasuryPDA,
  findGdrPDA,
  findVaultAuthorityPDA,
  findRarityPDA,
} from '@gemworks/gem-farm-ts';
import { programs } from '@metaplex/js';
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { sendTransaction } from '../util/index';

export async function initGemFarm(
  conn: Connection,
  wallet?: SignerWalletAdapter
) {
  const walletToUse = wallet ?? createFakeWallet();
  const farmIdl = await (await fetch('gem_farm.json')).json();
  const bankIdl = await (await fetch('gem_bank.json')).json();
  return new GemFarm(conn, walletToUse as any, farmIdl, bankIdl);
}

export const feeAccount = new PublicKey(
  '2xhBxVVuXkdq2MRKerE9mr2s1szfHSedy21MVqf8gPoM'
);

export class GemFarm extends GemFarmClient {
  constructor(conn: Connection, wallet: any, farmIdl: Idl, bankIdl: Idl) {
    super(conn, wallet, farmIdl, GEM_FARM_PROG_ID, bankIdl, GEM_BANK_PROG_ID);
  }

  async initFarmWallet(
    rewardAMint: PublicKey,
    rewardAType: any,
    rewardBMint: PublicKey,
    rewardBType: any,
    farmConfig: FarmConfig
  ) {
    const farm = Keypair.generate();
    const bank = Keypair.generate();

    const result = await this.initFarm(
      farm,
      this.wallet.publicKey,
      this.wallet.publicKey,
      bank,
      rewardAMint,
      rewardAType,
      rewardBMint,
      rewardBType,
      farmConfig
    );

    console.log('new farm started!', farm.publicKey.toBase58());
    console.log('bank is:', bank.publicKey.toBase58());

    return { farm, bank, ...result };
  }

  async updateFarmWallet(
    farm: PublicKey,
    newConfig?: FarmConfig,
    newManager?: PublicKey
  ) {
    const result = await this.updateFarm(
      farm,
      this.wallet.publicKey,
      newConfig,
      newManager
    );

    console.log('updated the farm');

    return result;
  }

  async authorizeFunderWallet(farm: PublicKey, funder: PublicKey) {
    const result = await this.authorizeFunder(
      farm,
      this.wallet.publicKey,
      funder
    );

    console.log('authorized funder', funder.toBase58());

    return result;
  }

  async deauthorizeFunderWallet(farm: PublicKey, funder: PublicKey) {
    const result = await this.deauthorizeFunder(
      farm,
      this.wallet.publicKey,
      funder
    );

    console.log('DEauthorized funder', funder.toBase58());

    return result;
  }

  async fundVariableRewardWallet(
    farm: PublicKey,
    rewardMint: PublicKey,
    amount: string,
    duration: string
  ) {
    const rewardSource = await this.findATA(rewardMint, this.wallet.publicKey);

    const config: VariableRateConfig = {
      amount: new BN(amount),
      durationSec: new BN(duration),
    };

    const result = this.fundReward(
      farm,
      rewardMint,
      this.wallet.publicKey,
      rewardSource,
      config
    );

    console.log('funded variable reward with mint:', rewardMint.toBase58());

    return result;
  }

  async fundFixedRewardWallet(
    farm: PublicKey,
    rewardMint: PublicKey,
    amount: string,
    duration: string,
    baseRate: string,
    denominator: string,
    t1RewardRate?: string,
    t1RequiredTenure?: string,
    t2RewardRate?: string,
    t2RequiredTenure?: string,
    t3RewardRate?: string,
    t3RequiredTenure?: string
  ) {
    const rewardSource = await this.findATA(rewardMint, this.wallet.publicKey);

    const config: FixedRateConfig = {
      schedule: {
        baseRate: new BN(baseRate),
        tier1: t1RewardRate
          ? {
            rewardRate: new BN(t1RewardRate),
            requiredTenure: new BN(t1RequiredTenure!),
          }
          : null,
        tier2: t2RewardRate
          ? {
            rewardRate: new BN(t2RewardRate),
            requiredTenure: new BN(t2RequiredTenure!),
          }
          : null,
        tier3: t3RewardRate
          ? {
            rewardRate: new BN(t3RewardRate),
            requiredTenure: new BN(t3RequiredTenure!),
          }
          : null,
        denominator: new BN(denominator),
      },
      amount: new BN(amount),
      durationSec: new BN(duration),
    };

    const result = await this.fundReward(
      farm,
      rewardMint,
      this.wallet.publicKey,
      rewardSource,
      undefined,
      config
    );

    console.log('funded fixed reward with mint:', rewardMint.toBase58());

    return result;
  }

  async cancelRewardWallet(farm: PublicKey, rewardMint: PublicKey) {
    const result = await this.cancelReward(
      farm,
      this.wallet.publicKey,
      rewardMint,
      this.wallet.publicKey
    );

    console.log('cancelled reward', rewardMint.toBase58());

    return result;
  }

  async lockRewardWallet(farm: PublicKey, rewardMint: PublicKey) {
    const result = await this.lockReward(
      farm,
      this.wallet.publicKey,
      rewardMint
    );

    console.log('locked reward', rewardMint.toBase58());

    return result;
  }

  async refreshFarmerWallet(farm: PublicKey, farmerIdentity: PublicKey) {
    const result = await this.refreshFarmer(farm, farmerIdentity);

    console.log('refreshed farmer', farmerIdentity.toBase58());

    return result;
  }

  async treasuryPayoutWallet(
    farm: PublicKey,
    destination: PublicKey,
    lamports: string
  ) {
    const result = await this.payoutFromTreasury(
      farm,
      this.wallet.publicKey,
      destination,
      new BN(lamports)
    );

    console.log('paid out from treasury', lamports);

    return result;
  }

  async initFarmerWallet(farm: PublicKey) {
    const result = await this.initFarmer(
      farm,
      this.wallet.publicKey,
      this.wallet.publicKey
    );

    console.log('initialized new farmer', this.wallet.publicKey.toBase58());

    return result;
  }

  async stakeWallet(farm: PublicKey) {
    const result = await this.stake(farm, this.wallet.publicKey);

    console.log('begun staking for farmer', this.wallet.publicKey.toBase58());

    return result;
  }

  async stakeGem(
    farm: PublicKey,
    gemMint: PublicKey,
    gemAmount: BN,
    vault: PublicKey,
    connection: Connection,
    nft: any
  ) {
    let identityPk = this.wallet.publicKey;

    const farmAcc = await this.fetchFarmAcc(farm);

    const creator = new PublicKey(
      (nft.onchainMetadata as any).data.creators[0].address
    );

    const [farmer, farmerBump] = await findFarmerPDA(farm, identityPk);
    const [farmAuth, farmAuthBump] = await findFarmAuthorityPDA(farm);
    const [gemBox, gemBoxBump] = await findGemBoxPDA(vault, gemMint);
    const [GDR, GDRBump] = await findGdrPDA(vault, gemMint);
    const [vaultAuth, vaultAuthBump] = await findVaultAuthorityPDA(vault);
    const [gemRarity, gemRarityBump] = await findRarityPDA(farmAcc.bank, gemMint);

    const [mintProof, _bump] = await findWhitelistProofPDA(farmAcc.bank, gemMint);
    const [creatorProof, _bump2] = await findWhitelistProofPDA(farmAcc.bank, creator);
    const metadata = await programs.metadata.Metadata.getPDA(gemMint);

    const remainingAccounts = [];
    if (mintProof)
      remainingAccounts.push({
        pubkey: mintProof,
        isWritable: false,
        isSigner: false,
      });
    if (metadata)
      remainingAccounts.push({
        pubkey: metadata,
        isWritable: false,
        isSigner: false,
      });
    if (creatorProof)
      remainingAccounts.push({
        pubkey: creatorProof,
        isWritable: false,
        isSigner: false,
      });

    let instructions = [];

    instructions.push(this.bankProgram.instruction.depositGem(
      vaultAuthBump,
      gemRarityBump,
      gemAmount,
      {
        accounts: {
          bank: farmAcc.bank,
          vault,
          owner: identityPk,
          authority: vaultAuth,
          gemBox,
          gemDepositReceipt: GDR,
          gemSource: nft.pubkey,
          gemMint,
          gemRarity,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: web3.SYSVAR_RENT_PUBKEY,
        },
        remainingAccounts
      }
    ));

    instructions.push(this.farmProgram.instruction.stake(
      farmAuthBump,
      farmerBump,
      {
        accounts: {
          farm,
          farmer,
          feeAcc: feeAccount,
          identity: identityPk,
          bank: farmAcc.bank,
          vault: vault,
          farmAuthority: farmAuth,
          gemBank: this.bankProgram.programId,
          systemProgram: SystemProgram.programId,
        },
      }
    ));

    const transaction = new web3.Transaction().add(...instructions)

    // let { value: { blockhash } } = await connection.getLatestBlockhashAndContext();
    // transaction.recentBlockhash = blockhash;
    let { blockhash } = await connection.getRecentBlockhash('processed');
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = this.wallet.publicKey;

    const signedTransaction = await this.wallet.signTransaction(
      transaction
    );

    // await web3.sendAndConfirmRawTransaction(connection, signedTransaction.serialize());
    await sendTransaction(signedTransaction, connection);
  }

  async unstakeWithdrawGems(
    farm: PublicKey,
    gemMint: PublicKey,
    gemAmount: BN,
    vault: PublicKey,
    connection: Connection,
    amountGemsInVault: number
  ) {
    let identityPk = this.wallet.publicKey;

    const farmAcc = await this.fetchFarmAcc(farm);

    const [farmer, farmerBump] = await findFarmerPDA(farm, identityPk);
    const [farmAuth, farmAuthBump] = await findFarmAuthorityPDA(farm);
    const [farmTreasury, farmTreasuryBump] = await findFarmTreasuryPDA(farm);
    const [gemBox, gemBoxBump] = await findGemBoxPDA(vault, gemMint);
    const [GDR, GDRBump] = await findGdrPDA(vault, gemMint);
    const [vaultAuth, vaultAuthBump] = await findVaultAuthorityPDA(vault);
    const [gemRarity, gemRarityBump] = await findRarityPDA(farmAcc.bank, gemMint);
    const gemDestination = await this.findATA(gemMint, this.wallet.publicKey);

    let instructions = [];

    instructions.push(this.farmProgram.instruction.unstake(
      farmAuthBump,
      farmTreasuryBump,
      farmerBump,
      false,
      {
        accounts: {
          farm,
          farmer,
          farmTreasury,
          identity: identityPk,
          bank: farmAcc.bank,
          vault: vault,
          farmAuthority: farmAuth,
          gemBank: this.bankProgram.programId,
          systemProgram: SystemProgram.programId,
          feeAcc: feeAccount,
        }
      }
    ));

    instructions.push(this.farmProgram.instruction.unstake(
      farmAuthBump,
      farmTreasuryBump,
      farmerBump,
      false,
      {
        accounts: {
          farm,
          farmer,
          farmTreasury,
          feeAcc: feeAccount,
          identity: identityPk,
          bank: farmAcc.bank,
          vault: vault,
          farmAuthority: farmAuth,
          gemBank: this.bankProgram.programId,
          systemProgram: SystemProgram.programId,
        }
      }
    ));

    instructions.push(this.bankProgram.instruction.withdrawGem(
      vaultAuthBump,
      gemBoxBump,
      GDRBump,
      gemRarityBump,
      gemAmount,
      {
        accounts: {
          bank: farmAcc.bank,
          vault: vault,
          owner: identityPk,
          authority: vaultAuth,
          gemBox,
          gemDepositReceipt: GDR,
          gemDestination,
          gemMint,
          gemRarity,
          receiver: identityPk,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: web3.SYSVAR_RENT_PUBKEY,
        },
      }
    ));

    if (amountGemsInVault > 1) {
      instructions.push(this.farmProgram.instruction.stake(
        farmAuthBump, farmerBump, {
        accounts: {
          farm,
          farmer,
          feeAcc: feeAccount,
          identity: identityPk,
          bank: farmAcc.bank,
          vault: vault,
          farmAuthority: farmAuth,
          gemBank: this.bankProgram.programId,
          systemProgram: SystemProgram.programId,
        },
      }
      ));
    }

    const transaction = new web3.Transaction().add(...instructions);

    let { blockhash } = await connection.getRecentBlockhash('processed');
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = this.wallet.publicKey;

    const signedTransaction = await this.wallet.signTransaction(
      transaction
    );

    await sendTransaction(signedTransaction, connection);

    // await web3.sendAndConfirmRawTransaction(connection, signedTransaction.serialize())
  }

  async unstakeDepositGems(
    farm: PublicKey,
    gemMint: PublicKey,
    gemSource: PublicKey,
    creator: PublicKey,
    gemAmount: BN,
    connection: Connection,
  ) {
    let identityPk = this.wallet.publicKey;

    const farmAcc = await this.fetchFarmAcc(farm);

    const [vault, vaultBump] = await findVaultPDA(farmAcc.bank, identityPk);
    const [farmer, farmerBump] = await findFarmerPDA(farm, identityPk);
    const [farmAuth, farmAuthBump] = await findFarmAuthorityPDA(farm);
    const [farmTreasury, farmTreasuryBump] = await findFarmTreasuryPDA(farm);
    const [gemBox, gemBoxBump] = await findGemBoxPDA(vault, gemMint);
    const [GDR, GDRBump] = await findGdrPDA(vault, gemMint);
    const [vaultAuth, vaultAuthBump] = await findVaultAuthorityPDA(vault);
    const [gemRarity, gemRarityBump] = await findRarityPDA(farmAcc.bank, gemMint);

    const [mintProof, _bump] = await findWhitelistProofPDA(farmAcc.bank, gemMint);
    const [creatorProof, _bump2] = await findWhitelistProofPDA(farmAcc.bank, creator);
    const metadata = await programs.metadata.Metadata.getPDA(gemMint);

    let instructions = [];

    instructions.push(this.farmProgram.instruction.unstake(
      farmAuthBump,
      farmTreasuryBump,
      farmerBump,
      false,
      {
        accounts: {
          farm,
          farmer,
          farmTreasury,
          feeAcc: feeAccount,
          identity: identityPk,
          bank: farmAcc.bank,
          vault: vault,
          farmAuthority: farmAuth,
          gemBank: this.bankProgram.programId,
          systemProgram: SystemProgram.programId,
        }
      }
    ));

    instructions.push(this.farmProgram.instruction.unstake(
      farmAuthBump,
      farmTreasuryBump,
      farmerBump,
      false,
      {
        accounts: {
          farm,
          farmer,
          farmTreasury,
          feeAcc: feeAccount,
          identity: identityPk,
          bank: farmAcc.bank,
          vault: vault,
          farmAuthority: farmAuth,
          gemBank: this.bankProgram.programId,
          systemProgram: SystemProgram.programId,
        }
      }
    ));

    const remainingAccounts = [];
    if (mintProof)
      remainingAccounts.push({
        pubkey: mintProof,
        isWritable: false,
        isSigner: false,
      });
    if (metadata)
      remainingAccounts.push({
        pubkey: metadata,
        isWritable: false,
        isSigner: false,
      });
    if (creatorProof)
      remainingAccounts.push({
        pubkey: creatorProof,
        isWritable: false,
        isSigner: false,
      });

    instructions.push(this.bankProgram.instruction.depositGem(
      vaultAuthBump,
      gemRarityBump,
      gemAmount,
      {
        accounts: {
          bank: farmAcc.bank,
          vault,
          owner: identityPk,
          authority: vaultAuth,
          gemBox,
          gemDepositReceipt: GDR,
          gemSource,
          gemMint,
          gemRarity,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: web3.SYSVAR_RENT_PUBKEY,
        },
        remainingAccounts
      }
    ));

    instructions.push(this.farmProgram.instruction.stake(
      farmAuthBump, farmerBump, {
      accounts: {
        farm,
        farmer,
        feeAcc: feeAccount,
        systemProgram: SystemProgram.programId,
        identity: identityPk,
        bank: farmAcc.bank,
        vault: vault,
        farmAuthority: farmAuth,
        gemBank: this.bankProgram.programId,
      },
    }
    ));

    const transaction = new web3.Transaction().add(...instructions);

    let { blockhash } = await connection.getRecentBlockhash('processed');
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = this.wallet.publicKey;

    const signedTransaction = await this.wallet.signTransaction(
      transaction
    );

    await sendTransaction(signedTransaction, connection);
    // await web3.sendAndConfirmRawTransaction(connection, signedTransaction.serialize())
  }

  async unstakeWallet(farm: PublicKey) {
    const result = await this.unstake(farm, this.wallet.publicKey);

    console.log('ended staking for farmer', this.wallet.publicKey.toBase58());

    return result;
  }

  async claimWallet(
    farm: PublicKey,
    rewardAMint: PublicKey,
    rewardBMint: PublicKey
  ) {
    const result = await this.claim(
      farm,
      this.wallet.publicKey,
      rewardAMint,
      rewardBMint
    );

    console.log('claimed rewards for farmer', this.wallet.publicKey.toBase58());

    return result;
  }

  async flashDepositRaw(
    farm: PublicKey,
    gemAmount: BN,
    gemMint: PublicKey,
    gemSource: PublicKey,
    creator: PublicKey,
    connection: Connection,
  ) {
    let identityPk = this.wallet.publicKey;

    const farmAcc = await this.fetchFarmAcc(farm);

    const [farmer, farmerBump] = await findFarmerPDA(farm, identityPk);
    const [vault, vaultBump] = await findVaultPDA(farmAcc.bank, identityPk);
    const [farmAuth, farmAuthBump] = await findFarmAuthorityPDA(farm);

    const [gemBox, gemBoxBump] = await findGemBoxPDA(vault, gemMint);
    const [GDR, GDRBump] = await findGdrPDA(vault, gemMint);
    const [vaultAuth, vaultAuthBump] = await findVaultAuthorityPDA(vault);
    const [gemRarity, gemRarityBump] = await findRarityPDA(farmAcc.bank, gemMint);

    const [mintProof, _bump] = await findWhitelistProofPDA(farmAcc.bank, gemMint);
    const [creatorProof, _bump2] = await findWhitelistProofPDA(farmAcc.bank, creator);
    const metadata = await programs.metadata.Metadata.getPDA(gemMint);

    const remainingAccounts = [];
    if (mintProof)
      remainingAccounts.push({
        pubkey: mintProof,
        isWritable: false,
        isSigner: false,
      });
    if (metadata)
      remainingAccounts.push({
        pubkey: metadata,
        isWritable: false,
        isSigner: false,
      });
    if (creatorProof)
      remainingAccounts.push({
        pubkey: creatorProof,
        isWritable: false,
        isSigner: false,
      });

    let instructions = [];

    const extraComputeIx = this.createExtraComputeIx(256000);
    instructions.push(extraComputeIx);

    instructions.push(this.farmProgram.instruction.flashDeposit(
      farmerBump,
      vaultAuthBump,
      gemRarityBump,
      gemAmount,
      {
        accounts: {
          farm,
          farmAuthority: farmAuth,
          farmer,
          feeAcc: feeAccount,
          identity: identityPk,
          bank: farmAcc.bank,
          vault,
          vaultAuthority: vaultAuth,
          gemBox,
          gemDepositReceipt: GDR,
          gemSource,
          gemMint,
          gemRarity,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: web3.SYSVAR_RENT_PUBKEY,
          gemBank: this.bankProgram.programId,
        },
        remainingAccounts,
      }
    ));

    const transaction = new web3.Transaction().add(...instructions);

    // let { value: { blockhash } } = await connection.getLatestBlockhashAndContext();
    // transaction.recentBlockhash = blockhash;
    let { blockhash } = await connection.getRecentBlockhash('processed');
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = this.wallet.publicKey;

    const signedTransaction = await this.wallet.signTransaction(
      transaction
    );

    await web3.sendAndConfirmRawTransaction(connection, signedTransaction.serialize());
  }

  async flashDepositWallet(
    farm: PublicKey,
    gemAmount: string,
    gemMint: PublicKey,
    gemSource: PublicKey,
    creator: PublicKey
  ) {
    const farmAcc = await this.fetchFarmAcc(farm);
    const bank = farmAcc.bank;

    const [mintProof, bump] = await findWhitelistProofPDA(bank, gemMint);
    const [creatorProof, bump2] = await findWhitelistProofPDA(bank, creator);
    const metadata = await programs.metadata.Metadata.getPDA(gemMint);

    const result = await this.flashDeposit(
      farm,
      this.wallet.publicKey,
      new BN(gemAmount),
      gemMint,
      gemSource,
      mintProof,
      metadata,
      creatorProof
    );

    console.log('added extra gem for farmer', this.wallet.publicKey.toBase58());

    return result;
  }

  async addToBankWhitelistWallet(
    farm: PublicKey,
    addressToWhitelist: PublicKey,
    whitelistType: WhitelistType
  ) {
    const result = await this.addToBankWhitelist(
      farm,
      this.wallet.publicKey,
      addressToWhitelist,
      whitelistType
    );

    console.log(`${addressToWhitelist.toBase58()} added to whitelist`);

    return result;
  }

  async removeFromBankWhitelistWallet(
    farm: PublicKey,
    addressToRemove: PublicKey
  ) {
    const result = await this.removeFromBankWhitelist(
      farm,
      this.wallet.publicKey,
      addressToRemove
    );

    console.log(`${addressToRemove.toBase58()} removed from whitelist`);

    return result;
  }
}
