
import {
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch,
  computed,
} from 'vue';
import useWallet from '@/composables/wallet';
import useCluster from '@/composables/cluster';
import { initGemFarm } from '@/common/gem-farm';
import { PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js';
import ConfigPane from '@/components/ConfigPane.vue';
import FarmerDisplaySimple from '@/components/gem-farm/FarmerDisplaySimple.vue';
import FarmDisplayHome from '@/components/gem-farm/FarmDisplayHome.vue';
import Vault from '@/components/gem-bank/Vault.vue';
import Tabs from '@/components/Tabs.vue';
import { INFT } from '@/common/web3/NFTget';
import { findFarmerPDA, stringifyPKsAndBNs } from '@gemworks/gem-farm-ts';
import { BN, AnchorProvider, Program, web3 } from '@project-serum/anchor';
import { useLoading } from 'vue-loading-overlay';

export default defineComponent({
  components: { Tabs, Vault, FarmerDisplaySimple, FarmDisplayHome, ConfigPane },
  setup() {
    const { wallet, getWallet } = useWallet();
    const { cluster, getConnection } = useCluster();
    const loadingAnim = useLoading();
    const loadingAnimParams = {};

    let gf: any;
    watch([wallet, cluster], async () => {
      await freshStart();
    });

    //needed in case we switch in from another window
    onMounted(async () => {
      await freshStart();

      refreshFarmAccount();
    });

    // --------------------------------------- farmer details
    // const farm = ref<string>('3TfMMjGRFzZUC9sjDvVdDyJPoSa8QVFyZ7e3qxqaLyV9');
    const farm = ref<string>('EyQ9eAYWwrMBUc66MyQWCP5Mm1ErxgLpR3Cq2uKhC64y');
    const farmAcc = ref<any>();

    const farmerIdentity = ref<string>();
    const farmerAcc = ref<any>();
    const farmerState = ref<string>();
    const farmerPDAVar = ref<any>();

    const availableA = ref<string>();
    const availableB = ref<string>();

    const currentTab = ref<number>(1);

    const walletBalance = ref<number>();

    // farm.value = 'EAvVuLu5iR6rJKg7yxVbaBqJtAoWUSE2JQr4qWFajwK7';

    //auto loading for when farm changes
    watch(farm, async () => {
      await freshStart();
    });

    const updateAvailableRewards = async () => {
      let availableACalculated = farmerAcc.value.rewardA.accruedReward.sub(
        farmerAcc.value.rewardA.paidOutReward
      );
      // console.log(
      //   'availableACalculated: ',
      //   farmerAcc.value.rewardA.accruedReward
      // );

      availableA.value = availableACalculated.toString();

      availableB.value = farmerAcc.value.rewardB.accruedReward
        .sub(farmerAcc.value.rewardB.paidOutReward)
        .toString();
    };

    const fetchFarn = async () => {
      farmAcc.value = await gf.fetchFarmAcc(new PublicKey(farm.value!));
      console.log(
        `farm found at ${farm.value}:`,
        stringifyPKsAndBNs(farmAcc.value)
      );
    };

    const fetchFarmer = async () => {
      const [farmerPDA] = await findFarmerPDA(
        new PublicKey(farm.value!),
        getWallet()!.publicKey!
      );
      farmerPDAVar.value = farmerPDA;
      farmerIdentity.value = getWallet()!.publicKey?.toBase58();
      farmerAcc.value = await gf.fetchFarmerAcc(farmerPDA);
      farmerState.value = gf.parseFarmerState(farmerAcc.value);
      await updateAvailableRewards();
      // console.log(
      //   `farmer found at ${farmerIdentity.value}:`,
      //   stringifyPKsAndBNs(farmerAcc.value)
      // );
    };

    const refreshFarmAccount = async () => {
      if (gf && farmerPDAVar.value) {
        // farmerAcc.value = await gf.fetchFarmerAcc(farmerPDAVar.value);
        await updateAvailableRewards();
      }

      // setTimeout(refreshFarmAccount, 15000);
    };

    const freshStart = async () => {
      if (getWallet() && getConnection()) {
        gf = await initGemFarm(getConnection(), getWallet()!);
        farmerIdentity.value = getWallet()!.publicKey?.toBase58();

        //reset stuff
        farmAcc.value = undefined;
        farmerAcc.value = undefined;
        farmerState.value = undefined;
        availableA.value = undefined;
        availableB.value = undefined;
        walletBalance.value = undefined;

        try {
          await fetchFarn();
          await fetchFarmer();
          await fetchWalletBalance();
        } catch (e) {
          console.log(`farm with PK ${farm.value} not found :(`);
        }
      } else {
        const idl = await (await fetch('gem_farm.json')).json();
        let farmProgram = new Program(
          idl,
          farm.value,
          new AnchorProvider(
            getConnection(),
            {
              signTransaction(tx: web3.Transaction) {
                return Promise.resolve(new web3.Transaction());
              },
              signAllTransactions(txs: web3.Transaction[]) {
                return Promise.resolve([new web3.Transaction()]);
              },
              publicKey: new PublicKey(farm.value),
            },
            AnchorProvider.defaultOptions()
          )
        );
        farmAcc.value = await farmProgram.account.farm.fetch(farm.value);
        // console.log('farmAcc.value: ', farmAcc.value);
      }
    };

    const initFarmer = async () => {
      await gf.initFarmerWallet(new PublicKey(farm.value!));
      await fetchFarmer();
    };

    // --------------------------------------- staking
    const beginStaking = async () => {
      await gf.stakeWallet(new PublicKey(farm.value!));
      await fetchFarmer();
      selectedNFTs.value = [];
    };

    const endStaking = async () => {
      await gf.unstakeWallet(new PublicKey(farm.value!));
      await fetchFarmer();
      selectedNFTs.value = [];
    };

    const claim = async () => {
      const loader = loadingAnim.show(loadingAnimParams);
      try {
        await gf.claimWallet(
          new PublicKey(farm.value!),
          new PublicKey(farmAcc.value.rewardA.rewardMint!),
          new PublicKey(farmAcc.value.rewardB.rewardMint!)
        );
        // When request is done
        loader.hide();
      } catch(e) {
        // If error
        loader.hide();
      }
      await fetchFarmer();
    };

    const handleRefreshFarmer = async () => {
      await fetchFarmer();
    };

    // --------------------------------------- adding extra gem
    const selectedNFTs = ref<INFT[]>([]);

    const handleNewSelectedNFT = async (newSelectedNFTs: INFT[]) => {
      console.log(`selected ${newSelectedNFTs.length} NFTs`);
      selectedNFTs.value = newSelectedNFTs;

      if (farmerState.value === 'staked') {
        await addGems();
        selectedNFTs.value = [];
        console.log('Starting staking');
        await fetchFarmer();
      }
    };

    const addSingleGem = async (
      gemMint: PublicKey,
      gemSource: PublicKey,
      creator: PublicKey
    ) => {
      // await gf.flashDepositWallet(
      //   new PublicKey(farm.value!),
      //   '1',
      //   gemMint,
      //   gemSource,
      //   creator
      // );
      const loader = loadingAnim.show(loadingAnimParams);
      try {
        await gf.unstakeDepositGems(
          new PublicKey(farm.value!),
          gemMint,
          gemSource,
          creator,
          new BN(1),
          getConnection()
        );
        // When request is done
        loader.hide();
      } catch(e) {
        // When request fails (need a notification of failure)
        loader.hide();
      }
      await fetchFarmer();
    };

    const addGems = async () => {
      await Promise.all(
        selectedNFTs.value.map((nft) => {
          const creator = new PublicKey(
            //todo currently simply taking the 1st creator
            (nft.onchainMetadata as any).data.creators[0].address
          );
          console.log('creator is', creator.toBase58());

          return addSingleGem(nft.mint, nft.pubkey!, creator);
        })
      );
      console.log(
        `added another ${selectedNFTs.value.length} gems into staking vault`
      );
    };

    const getShortWallet = () => {
      if (getWallet()) {
        const walletAddress: any = getWallet()!.publicKey?.toBase58();
        return `${walletAddress.slice(0, 8)}...`;
      }
    };

    const fetchWalletBalance = async () => {
      if (getWallet() && getConnection()) {
        const walletAddress: any = getWallet()!.publicKey?.toBase58();
        const pk = new PublicKey(walletAddress);
        try {
          walletBalance.value = await getConnection().getBalance(pk);
        } catch (error) {
          console.error(error);
        }
      }
    };

    let getWalletBalance = computed({
      get(): any {
        if (!walletBalance.value) {
          return undefined;
        }
        return (walletBalance.value / LAMPORTS_PER_SOL).toFixed(2);
      },
      set(newVal: number | undefined) {
        walletBalance.value = newVal;
      },
    });

    return {
      wallet,
      farm,
      farmAcc,
      farmer: farmerIdentity,
      farmerAcc,
      farmerState,
      availableA,
      availableB,
      initFarmer,
      beginStaking,
      endStaking,
      claim,
      handleRefreshFarmer,
      selectedNFTs,
      handleNewSelectedNFT,
      addGems,
      // toggleTabs,
      getShortWallet,
      fetchWalletBalance,
      getWalletBalance,
    };
  },
});
