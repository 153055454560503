
import { defineComponent, onMounted, ref, watch } from 'vue';
import NFTGrid from '@/components/gem-bank/NFTGrid.vue';
import ArrowButton from '@/components/ArrowButton.vue';
import useWallet from '@/composables/wallet';
import useCluster from '@/composables/cluster';
import {
  getNFTMetadataForMany,
  getNFTsByOwner,
  INFT,
} from '@/common/web3/NFTget';
import { initGemBank } from '@/common/gem-bank';
import { initGemFarm } from '@/common/gem-farm';
import { PublicKey } from '@solana/web3.js';
import { getListDiffBasedOnMints, removeManyFromList } from '@/common/util';
import { BN } from '@project-serum/anchor';
import { useLoading } from 'vue-loading-overlay';

export default defineComponent({
  components: { ArrowButton, NFTGrid },
  props: {
    vault: String,
    farm: String,
    farmerState: String,
  },
  emits: ['selected-wallet-nft', 'refresh-farmer'],
  setup(props, ctx) {
    const { wallet, getWallet } = useWallet();
    const { cluster, getConnection } = useCluster();

    const verifiedCreator = '2AyjKNJPopTcE8nx1bnCXG8VRsKu8Ec5EuaJqZvE6yhP';
    const verifiedCreator2 = '4omMKhqAZafpAS3HrNeyvhi7t1khPmZ8KVWb56HQjyXS';
    const verifiedCreator3 = 'GoNoCov8JgMyiHhCnKFq5eWw4hxskUxbZLxnW5oBGF8N';

    const loadingAnim = useLoading();
    const loadingAnimParams = {};

    // --------------------------------------- state

    //current walet/vault state
    const currentWalletNFTs = ref<INFT[]>([]);
    const currentVaultNFTs = ref<INFT[]>([]);
    //selected but not yet moved over in FE
    const selectedWalletNFTs = ref<INFT[]>([]);
    const selectedVaultNFTs = ref<INFT[]>([]);
    //moved over in FE but not yet onchain
    const desiredWalletNFTs = ref<INFT[]>([]);
    const desiredVaultNFTs = ref<INFT[]>([]);
    //moved over onchain
    const toWalletNFTs = ref<INFT[]>([]);
    const toVaultNFTs = ref<INFT[]>([]);
    //UI boolean for leaf components
    const isVault = ref<boolean>(true);

    const isUnstakedActive = ref<boolean>(true);
    const isStakedActive = ref<boolean>(false);

    let gf: any;

    // --------------------------------------- populate initial nfts

    const populateWalletNFTs = async () => {
      // zero out to begin with
      currentWalletNFTs.value = [];
      selectedWalletNFTs.value = [];
      desiredWalletNFTs.value = [];

      if (getWallet()) {
        currentWalletNFTs.value = await getNFTsByOwner(
          getWallet()!.publicKey!,
          getConnection()
        );

        // console.log('currentWalletNFTs.value: ', currentWalletNFTs.value);

        currentWalletNFTs.value = currentWalletNFTs.value.filter((nft) => {
          //@ts-ignore
          if (nft.onchainMetadata.data.creators) {
            return (
              //@ts-ignore
              (nft.onchainMetadata.data.creators[0].address ===
                verifiedCreator ||
                //@ts-ignore
                nft.onchainMetadata.data.creators[0].address ===
                  verifiedCreator2 ||
                //@ts-ignore
                nft.onchainMetadata.data.creators[0].address ===
                  verifiedCreator3) &&
              //@ts-ignore
              nft.onchainMetadata.data.creators[0].verified === 1
            );
          }
        });
        desiredWalletNFTs.value = [...currentWalletNFTs.value];
      }
    };

    const populateVaultNFTs = async () => {
      // zero out to begin with
      currentVaultNFTs.value = [];
      selectedVaultNFTs.value = [];
      desiredVaultNFTs.value = [];

      const foundGDRs = await gb.fetchAllGdrPDAs(vault.value);
      if (foundGDRs && foundGDRs.length) {
        gdrs.value = foundGDRs;
        console.log(`found a total of ${foundGDRs.length} gdrs`);

        const mints = foundGDRs.map((gdr: any) => {
          return { mint: gdr.account.gemMint };
        });
        currentVaultNFTs.value = await getNFTMetadataForMany(
          mints,
          getConnection()
        );
        desiredVaultNFTs.value = [...currentVaultNFTs.value];
        console.log(
          `populated a total of ${currentVaultNFTs.value.length} vault NFTs`
        );
      }
    };

    const updateVaultState = async () => {
      vaultAcc.value = await gb.fetchVaultAcc(vault.value);
      bank.value = vaultAcc.value.bank;
      vaultLocked.value = vaultAcc.value.locked;
    };

    watch([wallet, cluster], async () => {
      gb = await initGemBank(getConnection(), getWallet()!);
      gf = await initGemFarm(getConnection(), getWallet()!);

      //populate wallet + vault nfts
      await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
    });

    onMounted(async () => {
      gb = await initGemBank(getConnection(), getWallet()!);
      gf = await initGemFarm(getConnection(), getWallet()!);

      //prep vault + bank variables
      vault.value = new PublicKey(props.vault!);
      await updateVaultState();

      //populate wallet + vault nfts
      await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
    });

    // --------------------------------------- moving nfts

    const handleWalletSelected = async (e: any) => {
      if (e.selected) {
        selectedWalletNFTs.value.push(e.nft);
      } else {
        const index = selectedWalletNFTs.value.indexOf(e.nft);
        selectedWalletNFTs.value.splice(index, 1);
      }

      if (props.farmerState === 'staked') {
        console.log('Is staked, try to BLITZ ADD GEM');
        ctx.emit('selected-wallet-nft', selectedWalletNFTs.value);
      } else {
        let gemAmount = new BN(1);
        let gemMint = e.nft.mint;

        console.log('Is not staked, MANUAL WAY');

        const loader = loadingAnim.show(loadingAnimParams);
        try {
          await gf.stakeGem(
            new PublicKey(props.farm!),
            gemMint,
            gemAmount,
            vault.value,
            getConnection(),
            e.nft
          );
          // When request is done
          loader.hide();
        } catch(e) {
          // If error
          loader.hide();
        }
        // moveNFTsFE(false);
        // await moveNFTsOnChain();
        // await gf.stakeWallet(new PublicKey(props.farm!));
        ctx.emit('refresh-farmer');
      }
    };

    const handleVaultSelected = async (e: any) => {
      if (e.selected) {
        selectedVaultNFTs.value.push(e.nft);
      } else {
        const index = selectedVaultNFTs.value.indexOf(e.nft);
        selectedVaultNFTs.value.splice(index, 1);
      }
      // moveNFTsFE(true);
      let gemAmount = new BN(1);
      let gemMint = e.nft.mint;
      let amountGemsInVault = currentVaultNFTs.value.length;

      const loader = loadingAnim.show(loadingAnimParams);
      try {
        await gf.unstakeWithdrawGems(
          new PublicKey(props.farm!),
          gemMint,
          gemAmount,
          vault.value,
          getConnection(),
          amountGemsInVault
        );
        // When request is done
        loader.hide();
      } catch(e) {
        // If error
        loader.hide();
      }
      ctx.emit('refresh-farmer');
      selectedVaultNFTs.value = [];
    };

    const moveNFTsFE = (moveLeft: boolean) => {
      if (moveLeft) {
        //push selected vault nfts into desired wallet
        desiredWalletNFTs.value.push(...selectedVaultNFTs.value);
        //remove selected vault nfts from desired vault
        removeManyFromList(selectedVaultNFTs.value, desiredVaultNFTs.value);
        //empty selection list
        selectedVaultNFTs.value = [];
      } else {
        //push selected wallet nfts into desired vault
        desiredVaultNFTs.value.push(...selectedWalletNFTs.value);
        //remove selected wallet nfts from desired wallet
        removeManyFromList(selectedWalletNFTs.value, desiredWalletNFTs.value);
        // force update
        toVaultNFTs.value = getListDiffBasedOnMints(
          desiredVaultNFTs.value,
          currentVaultNFTs.value
        );
        //empty selected walelt
        selectedWalletNFTs.value = [];
      }
      console.log('DONE move nft');
    };

    //todo jam into single tx
    const moveNFTsOnChain = async () => {
      console.log('START move on chain');
      for (const nft of toVaultNFTs.value) {
        console.log(nft);
        const creator = new PublicKey(
          //todo currently simply taking the 1st creator
          (nft.onchainMetadata as any).data.creators[0].address
        );
        console.log('creator is', creator.toBase58());
        await depositGem(nft.mint, creator, nft.pubkey!);
      }
      for (const nft of toWalletNFTs.value) {
        await withdrawGem(nft.mint);
      }
      await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
    };

    //to vault = vault desired - vault current
    watch(
      desiredVaultNFTs,
      () => {
        toVaultNFTs.value = getListDiffBasedOnMints(
          desiredVaultNFTs.value,
          currentVaultNFTs.value
        );
        // console.log('to vault nfts are', toVaultNFTs.value);
      },
      { deep: true }
    );

    //to wallet = wallet desired - wallet current
    watch(
      desiredWalletNFTs,
      () => {
        toWalletNFTs.value = getListDiffBasedOnMints(
          desiredWalletNFTs.value,
          currentWalletNFTs.value
        );
        // console.log('to wallet nfts are', toWalletNFTs.value);
      },
      { deep: true }
    );

    // --------------------------------------- gem bank

    let gb: any;
    const bank = ref<PublicKey>();
    const vault = ref<PublicKey>();
    const vaultAcc = ref<any>();
    const gdrs = ref<PublicKey[]>([]);
    const vaultLocked = ref<boolean>(false);

    const depositGem = async (
      mint: PublicKey,
      creator: PublicKey,
      source: PublicKey
    ) => {
      const { txSig } = await gb.depositGemWallet(
        bank.value,
        vault.value,
        new BN(1),
        mint,
        source,
        creator
      );
      console.log('deposit done', txSig);
    };

    const withdrawGem = async (mint: PublicKey) => {
      const { txSig } = await gb.withdrawGemWallet(
        bank.value,
        vault.value,
        new BN(1),
        mint
      );
      console.log('withdrawal done', txSig);
    };


    const setUnstakedActive = function() {
      isUnstakedActive.value = true;
      isStakedActive.value = false;
    };
    const setStakedActive = function() {
      isUnstakedActive.value = false;
      isStakedActive.value = true;
    };

    // --------------------------------------- return

    return {
      wallet,
      desiredWalletNFTs,
      desiredVaultNFTs,
      toVaultNFTs,
      toWalletNFTs,
      handleWalletSelected,
      handleVaultSelected,
      moveNFTsFE,
      moveNFTsOnChain,
      bank,
      isVault,
      // eslint-disable-next-line vue/no-dupe-keys
      vault,
      vaultLocked,
      isUnstakedActive,
      isStakedActive,
      setStakedActive,
      setUnstakedActive
    };
  },
});
