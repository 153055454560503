import { readonly, ref } from 'vue';
import { Commitment, Connection } from '@solana/web3.js';
import { tokenAuthFetchMiddleware } from "@strata-foundation/web3-token-auth";

export enum Cluster {
  Mainnet = 'mainnet',
  Devnet = 'devnet',
  Testnet = 'testnet',
  Localnet = 'localnet',
}

const clusterURLMapping = {
  mainnet: process.env.VUE_APP_MAINNET_URL || 'https://convincing-sleek-scion.solana-mainnet.quiknode.pro/c6a8b20f47711c2f20ab33aca527c3edb1655970/',
  devnet: process.env.VUE_APP_DEVNET_URL || 'https://api.devnet.solana.com',
  testnet: process.env.VUE_APP_TESTNET_URL || 'https://api.testnet.solana.com',
  localnet: process.env.VUE_APP_LOCALNET_URL || 'http://localhost:8899',
};

const cluster = ref<Cluster>(Cluster.Mainnet);

export default function useCluster() {
  const getClusterURL = (): string => clusterURLMapping[cluster.value];

  const getGenesysGoToken = async () => {
    const URL = process.env.REACT_APP_CHART_API || 'https://dry-ravine-67635.herokuapp.com';

    const req = await fetch(`${URL}/login`);
    const { access_token }: { access_token: string } = await req.json();
    // console.log('access_token: ', access_token);
    return access_token;
  };

  const getConnection = (committment?: Commitment): Connection =>
    new Connection(getClusterURL(), {
      commitment: committment ?? 'processed'
    });

  const setCluster = (newCluster: Cluster) => {
    cluster.value = newCluster;
    // capping at 20 chars due to security (not to expose the token)
    console.log(
      `Cluster updated,  now ${newCluster} (${getClusterURL().substr(0, 20)})`
    );
  };

  return {
    cluster: readonly(cluster),
    getClusterURL,
    getConnection,
    setCluster,
  };
}
